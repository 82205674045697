import BaseModel from './BaseModel';
import { db, firebase } from '../utilities'; 

interface Rating {
  id?: string;
  name: string;
  description: string;
  type: string;
  formId: string;
}

class Ratings extends BaseModel {
  constructor() {
    super(db.collection('ratings'));
  }

  async getAllRatings(formId: string | undefined): Promise<Rating[]> {
    try {
      if (!formId) {
        console.log("formId is undefined. Skipping query.");
        return [];
      }
  
      const snapshot = await this.baseQuery
        .where('status', '==', true)
        .where('formId', '==', formId)
        .get(); // Remove orderBy for now to include all records
  
      if (snapshot.empty) {
        return [];
      }
  
      // Map documents and include all necessary fields from Rating interface
      const ratings = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name || "",           // Provide a default if necessary
          description: data.description || "",
          type: data.type || "",
          formId: data.formId || formId,    // Use formId from the parameter if missing
          order: data.order !== undefined ? data.order : Number.MAX_SAFE_INTEGER,
        };
      });
  
      console.log("icon before sorting :: ", JSON.stringify(ratings, null, 2));

      // Sort by the extracted `order` field
      const sortedratings = ratings.sort((a, b) => a.order - b.order);
  
      console.log("icon after sorting :: ", JSON.stringify(sortedratings, null, 2));
  
      return sortedratings;
      
    } catch (error) {
      console.error("Error fetching ratings:", error);
      return [];
    }
  }
  
  

  async createRating(rating: Rating): Promise<void> {
    try {
      const snapshot = await this.baseQuery
        .where('type', '==', rating.type)
        .where('formId', '==', rating.formId )
        .where('name', '==', rating.name)
        .get();
      if (!snapshot.empty) {
        throw new Error(`${rating.type} name must be unique`);
      }
      await this.baseQuery.add(rating);
    } catch (error) {
      console.error( error);
      throw error;
    }
  }

  async updateRating(id: string, rating: Rating): Promise<void> {
    try {
      const snapshot = await this.baseQuery
        .where('type', '==', rating.type)
        .where('name', '==', rating.name)
        .where('formId', '==', rating.formId)
        .where(firebase.firestore.FieldPath.documentId(), '!=', id)
        .get();
      if (!snapshot.empty) {
        throw new Error(`${rating.type} name must be unique`);
      }
      await this.baseQuery.doc(id).set(rating, { merge: true });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteRating(id: string): Promise<void> {
    try {
      await this.baseQuery.doc(id).update({ status: false });
    } catch (error) {
      console.error( error);
      throw error;
    }
  }

  async updateRatingOrder(id: string, order: number): Promise<void> {
    try {
      if (!id || order === undefined) {
        throw new Error("ID and order are required.");
      }

      // Update only the order field for the specified document
      await this.baseQuery.doc(id).update({ order: order });
    } catch (error) {
      console.error("Error in updateRatingOrder:", error);
      throw error;
    }
  }

}

export default Ratings;
