import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import change from './change'
import ConditionGroupComponent from '../../FormDetail/FieldsPanel/ConditionGroupComponent'


export function RubricActionList({ actions, handleCloseAction, fields, cArgs,
  field, section, sections, updateActions, optionActions, selectedRubricObj }) {

  const [addAction, setAddAction] = useState(false)
  const [selectedActionList, setSelectedActionList] = useState(optionActions)

  useEffect(() => {
    setSelectedActionList(optionActions)
  }, [JSON.stringify(optionActions)])

  const handleCloseActionSaveRedux = () => {
    change(...cArgs, selectedActionList, "isOptionActions")
    updateActions(selectedActionList)
    handleCloseAction();
  }

  const handleActionSelection = (rec, e) => {
    let selectedActionList1 = [...selectedActionList]
    if (e.target.checked && !selectedActionList1.find(x => x.actionIndex === rec.actionIndex)) {
      let clonerec = JSON.parse(JSON.stringify(rec));
      selectedActionList1.push(clonerec)
    } else {
      selectedActionList1 = selectedActionList1.filter(x => x.actionIndex !== rec.actionIndex)
    }
    setSelectedActionList(selectedActionList1)
  }

  const handleUpdateActions = (dataRec) => {
    let selectedActionList1 = [...selectedActionList]
    const selectedAction = selectedActionList1.find(x => x.id === dataRec.id)
    if(selectedAction) {
      selectedAction.conditionGroup = dataRec.conditionGroup
    } 
    setSelectedActionList(selectedActionList1)
  }
  const handleSaveActions = () => {
    setAddAction(false)
  }

  return (
    <React.Fragment>
      {addAction ?
        <div className='addAction-container addAction-container-save'>
        <React.Fragment>
          <table className='addAction-rubric' style={{ transition: 'all 1s linear;' }}>
            <thead>
              <tr className='table-header'>
                <th className='addAction-rubric-table-thead-action'>Action</th>
                <th className='addAction-rubric-table-thead-strategy'>Strategy</th>
                <th className='addAction-rubric-table-thead-actionDesc'>Action Description</th>
                <th className='addAction-rubric-table-thead-select'>Select</th>
              </tr>
            </thead>
            <tbody>
              {actions.map(rec => (
                <tr>
                  <td>{rec.actionIndex}</td>
                  <td>{rec.strategy}</td>
                  <td className="action-description" dangerouslySetInnerHTML={{ __html: rec.actionDescription }} />
                  <td  className='addAction-rubric-select'>
                    <input type="checkbox"
                      key={`${rec.actionIndex}-${selectedActionList.find(x => x.id === rec.id)}`}
                      defaultChecked={selectedActionList.find(x => x.id === rec.id)}
                      onChange={(e) => handleActionSelection(rec, e)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='action-buttons'>
            <Button type='primary' onClick={handleSaveActions} className='action-button-addaction'>Save Actions</Button>
            <Button type='primary' onClick={() => setAddAction(false)}>Close</Button>
          </div>
        </React.Fragment>
        </div>
        :
        <div className='addAction-container'>
        <React.Fragment>
          <table className='addAction-rubric'>
            <thead>
              <tr className='table-header'>
                <th className='addAction-rubric-table-thead-action'>Action</th>
                <th className='addAction-rubric-table-thead-strategy'>Strategy</th>
                <th className='addAction-rubric-table-thead-actionDesc'>Additional Conditions</th>
              </tr>
            </thead>
            <tbody>
              {
                selectedActionList.map(rec => (
                  <tr>
                    <td>{rec.actionIndex}</td>
                    <td>{rec.strategy}</td>
                    <td>
                      <ConditionGroupComponent 
                        fields={fields} field={field}
                        section={section} sections={sections}
                        data={rec}
                        selectedActionList={selectedActionList}
                        selectedRubricObj={selectedRubricObj}
                        handleUpdateActions={ handleUpdateActions} /></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className='action-buttons'>
            <Button type='primary' onClick={() => { setAddAction(true) }  } className='action-button-addaction'>Add Action</Button>
            <Button type='primary' onClick={handleCloseActionSaveRedux}>Close</Button>
          </div>
        </React.Fragment>
        </div>
      }
    </React.Fragment>
  )
}
export default RubricActionList
