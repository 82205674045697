import React, { useEffect, useState } from 'react';
import { history } from '../../history';
import { auth } from '../../Auth';
import { Button, Modal } from 'antd'; // Added Modal for confirmation dialog
import { connect } from 'react-redux';
import {
  highlightErrors,
  reviewMode,
  setStatus,
  setLoading,
  updateProgress
} from '../action_creators'; // Added updateProgress action
import { saveIntake } from '../utilities';
import { loggerEffect } from '../../effects';

import IntakeStatus from './IntakeStatus';
import ReviewOptions from './ReviewOptions';
import ActiveSectionDisplay from './ActiveSectionDisplay';
import { statusLogs } from './statusLogs';

const mapStateToProps = (state) => ({
  project: state.project,
  corrections: Object.values(state.project.intake.corrections || {}).filter((i) => i !== undefined).length,
  reviewMode: state.meta.reviewMode,
});

const mapDispatchToProps = (dispatch) => ({
  highlightErrors: (errors) => {
    dispatch(highlightErrors(errors));
  },
  toggleReviewMode: (bool) => dispatch(reviewMode(bool)),
  setStatus: (status) => dispatch(setStatus(status)),
  setLoading: (bool) => dispatch(setLoading(bool)),
  updateProgress: () => dispatch(updateProgress()), // Added updateProgress to dispatch actions
});

export function Details({
  project,
  corrections,
  reviewMode,
  highlightErrors,
  setStatus,
  setLoading,
  toggleReviewMode,
  updateProgress,
}) {
  const [logger, setLogger] = useState(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false); // Added state for confirmation modal visibility
  const [loadingTemp, setLoadingTemp] = useState(false); // Added state for temporary loading indicator

  useEffect(loggerEffect(setLogger, `/projects/${project.id}`), []);

  useEffect(() => {
    updateProgress(); // Recalculate progress when the intake form is updated
  }, [project.intake.fields, project.intake.sections, project.intake.order, updateProgress]);
  
  async function finalize(done, status) {
    logger.categoryId = project.id;

    console.info(`Finalize done:${done} status: ${status}`);
    try {
      if (!done && status === 'Review') {
        setStatus('Needs Corrections');
      }

      setLoadingTemp(true);
      // these functions will be called on projects Page now

      await saveIntake(done);

      // Call the appropriate status log based on the new status
      const newStatus = project.intake.skipReview && project.status === 'In Progress' ? 'Finalized' : status;
      await statusLogs[newStatus](logger, project.site.siteAddress, corrections);

      history.push('/projects');
      //history.push('/projects', { sourcePage: 'details', done })
    } catch (e) {
      setLoadingTemp(false);
      console.log(e);
      highlightErrors(e.errors);
      logger.event(
        `You must fill out all required fields to submit this project; missing fields have been highlighted`,
        null,
        2,
        { [auth.sunkaizenUser.id]: auth.sunkaizenUser.type }
      );
      return;
    } finally {
      setLoading(false);
    }
  }

  const submitReview = () => {
    setConfirmModalVisible(false);
    console.log('project.status ::: ' + project.intake.skipReview);

    // If skipReview is true and status is 'In Progress', treat it as 'Finalized'
    const finalStatus = project.intake.skipReview && project.status === 'In Progress' ? 'Finalized' : project.status;

    if (project && project.rubric && project.rubric !== 'Auto-Rubric') {
      finalize(true, finalStatus);
    } else {
      logger.event(
        `You must fill out all required fields to submit this project; missing fields have been highlighted`,
        null,
        2,
        { [auth.sunkaizenUser.id]: auth.sunkaizenUser.type }
      );
    }
  };

  const handleSubmitReview = () => {
    setConfirmModalVisible(true); // Show confirmation modal on submit
  };

  const handleCancelSubmit = () => {
    setConfirmModalVisible(false); // Hide confirmation modal
  };

  const handleSave = () => {
    saveIntake(); // Save intake without navigating away
  };

  const handleSaveAndExit = () => {
    saveIntake().then(() => history.push(`/projects/${project.id}/manage`)); // Save intake and navigate to manage page
  };

  const { siteOwnerName, siteAddress } = project.site || {};

  return (
    <div className="padding-bottom-1" data-cy="intakeDetails">
      <div className="background-white">
        <IntakeStatus status={project.status} />
        <div className="intake-details-box padding-2">
          <p>
            <strong>Site Owner Name:</strong> {siteOwnerName || 'N/A'}
          </p>
          <p>
            <strong>Property Address:</strong> {siteAddress || 'N/A'}
          </p>
        </div>
        <ActiveSectionDisplay />
        <div
          className="flex-row justify-space-between"
          style={{ borderBottom: '0.1rem solid #DFE3E8', padding: '1.5rem' }}
        >
          <div className="bold">Started</div>
          <div>{project.startedAt && project.startedAt.toDate().toDateString()}</div>
        </div>
        {!loadingTemp && (
          <div className="flex-row align-center justify-center padding-2">
          {['In Progress', 'Needs Corrections', 'Review'].includes(project.status) && (
            <React.Fragment>
              <Button
                onClick={handleSave}
                style={{ marginRight: '1rem' }}
                type={project.status === 'Review' ? 'primary' : 'default'}
              >
                Save
              </Button>
              {project.status !== 'Review' && (
                <Button onClick={handleSaveAndExit} style={{ marginRight: '1rem' }} type="primary">
                  Save and Exit
                </Button>
              )}
              {['In Progress', 'Needs Corrections'].includes(project.status) && (
                <Button onClick={handleSubmitReview} type="primary">
                  {project.intake.skipReview ? 'Finalize' : 'Submit for Review'}
                </Button>
              )}
            </React.Fragment>
          )}
          {project.status === 'Review' &&
            ['super-admin', 'admin', 'quality-manager', 'client'].includes(auth.sunkaizenUser.type) && (
              <ReviewOptions
                {...{
                  reviewMode,
                  toggleReviewMode,
                  corrections,
                  status: project.status,
                  finalize,
                }}
              />
            )}
        </div>
        )}
      </div>
      <Modal
        title="Confirm Submission"
        visible={confirmModalVisible}
        onOk={submitReview}
        onCancel={handleCancelSubmit}
      >
        <p>
          {project.intake.skipReview
            ? 'Are you sure you want to finalize this form'
            : 'Are you sure you want to submit for review'}
          ?
        </p>
      </Modal>
    </div>
  );
}

const WrappedDetails = connect(mapStateToProps, mapDispatchToProps)(Details);
export default WrappedDetails;
