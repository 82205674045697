import DB from '../../DAL/DB'

export default async function generateProjectShell(projectType) {
  const intake = JSON.parse(JSON.stringify(projectType))
  try {
    const formSection = new DB.FormSection(projectType.id)
    const {ok, data: sectionsArray} = await formSection.get()

    if (!ok) {
      throw new Error('problem getting form sections')
      // return intake
    }

    const sections = {}
    const promises = sectionsArray.map((section) => {
      const formField = new DB.FormField(projectType.id, section.id)
      return formField.get().then(({ok, data: fields}) => {
        if (ok) section.fields = fields
        sections[section.id] = section
      })
    })

    await Promise.all(promises)
    intake.sections = sections

    return intake
  } catch (e) {
    console.log(`error in generateProjectShell`, e)
  }
}
