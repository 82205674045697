import React, { useEffect, useState, useReducer } from "react";
import { useParams, useHistory } from "react-router-dom";
import CreateClientUser from "./UserBuilder/UserInformation/CreateClientUser";
import CreateProjectClient from "./ProjectBuilder/ProjectCreator/CreateProjectClient";
import SpecialAccess from "./DAL/SpecialAccess";
import { validateField } from "./validators";
import { Button, message } from 'antd';
import axios from 'axios';
import {loggerEffect} from './effects'
import 'firebase/auth';
import { FUNCTIONS_URL } from "./config";

const api_url = `${FUNCTIONS_URL}/createSpecialAccessUser`
function userReducer(state, action) {
  switch (action.type) {
    case "setField":
      return { ...state, [action.field]: action.value };
    case "clearFields":
      return { errors: {} };
    default:
      return state;
  }
}

function projectReducer(state, action) {
  switch (action.type) {
    case "setField":
      return { ...state, [action.field]: action.value };
    case "clearFields":
      return { errors: {} };
    default:
      return state;
  }
}

export default function CreateClient() {
  const { specialurl } = useParams();
  const history = useHistory();
  
  const [userState, userDispatch] = useReducer(userReducer, { errors: {} });
  const [projectState, projectDispatch] = useReducer(projectReducer, { errors: {} });

  const [validUrl, setValidUrl] = useState(null);
  const [specialAccessData, setSpecialAccessData] = useState(null);
  const [logger, setLogger] = useState({ error: console.log, event: console.log });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loggerEffect(setLogger)();
    validateSpecialUrl();
    userDispatch({ type: "clearFields" });
    projectDispatch({ type: "clearFields" });
  }, [specialurl]);
  function sanitizeObject(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null)
    );
  }
  async function validateSpecialUrl() {
    try {
      const allData = await SpecialAccess.getAll();
      const foundRecord = allData.find((record) => record.specialurl === specialurl && record.status === "new");
      if (foundRecord) {
        setValidUrl(true);
        setSpecialAccessData(foundRecord);
      } else {
        setValidUrl(false);
      }
    } catch (error) {
      console.error("Error validating special URL:", error);
      setValidUrl(false);
    }
  }
  async function handleSubmit() {
    setLoading(true);
  
    const requiredUserFields = ['name', 'email', 'address', 'phone'];
    const missingUserFields = requiredUserFields.filter(field => !userState[field]);
    const requiredProjectFields = ['siteOwnerName', 'siteAddress', 'siteRepEmail', 'siteRepPhone'];
    const missingProjectFields = requiredProjectFields.filter(field => !projectState[field]);
  
    if (missingUserFields.length > 0 || missingProjectFields.length > 0) {
      console.log(missingUserFields, missingProjectFields, "missing fields")
      message.error('Please fill out all required fields');
      setLoading(false);
      return;
    }
  
    try {
      const sanitizedUserData = sanitizeObject(userState);
      const sanitizedProjectData = sanitizeObject(projectState);
      const payload = { specialurl, userData: sanitizedUserData, projectData: sanitizedProjectData };
      const response = await axios.post(
        api_url,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        message.success('User and project created successfully!');
        resetFields();
        history.push('/login');
      } else {
        message.error('Failed to create user and project');
      }
    } catch (error) {
      console.error('Error during user and project creation:', error);
      if(error == "Error: Network Error"){
         message.success('User and project created successfully!');
         resetFields();
         history.push('/login');
      }else{
        message.error('Error during creation process');
      }
    } finally {
      setLoading(false);
    }
  }

  const setUserField = (field) => (value) => {
    userDispatch({ type: "setField", field, value });
  };

  const setProjectField = (field) => (value) => {
    projectDispatch({ type: "setField", field, value });
  };

  const resetFields = () => {
    userDispatch({ type: "clearFields" });
    projectDispatch({ type: "clearFields" });
    validateSpecialUrl();
  };

  if (validUrl === null) {
    return <div>Loading...</div>;
  }

  if (!validUrl) {
    return <div>Error: Invalid or expired URL.</div>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <div style={{ flex: 1, marginRight: "10px" }}>
          <CreateClientUser fields={userState} setField={setUserField} />
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <CreateProjectClient fields={projectState} setField={setProjectField} />
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button type="primary" loading={loading} onClick={handleSubmit}>
          Submit
        </Button>
        <Button onClick={() => history.goBack()} style={{ marginLeft: "10px" }}>
          Cancel
        </Button>
      </div>
    </div>
  );
}