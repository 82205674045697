import React, { useEffect, useRef } from 'react';
import { Button, message } from 'antd';
import { Sticky } from 'react-sticky';
import { history } from '../history';
import { saveIntake } from './utilities';
import { silentSaveIntake } from './utilities';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

export function TabBar({ project, highlightErrors, props, Default }) {
  const projectIdRef = useRef(project.id); // Reference to store the current project ID
  const DEBOUNCE_INTERVAL = 600000; // 10 minutes in milliseconds

  async function saver(route) {
    try {
      await saveIntake()
      if (route) history.push(route)
    } catch (e) {
      highlightErrors(e)
      console.log(`some errors occurred that prevent submission`, e)
    }
  }

  async function silentSaver(route = null) {
    try {
      //message.loading({ content: 'Auto-saving data...', key: 'saving', duration: 1 });
      if (project?.id === projectIdRef.current) { // Only save if the current project matches
          await silentSaveIntake(); // Trigger the save operation
      }
      //message.success({ content: 'Data saved successfully!', key: 'saving', duration: 2 });
    } catch (e) {
      highlightErrors(e);
      message.error({ content: 'Error saving data.', key: 'saving', duration: 2 });
      console.log('Error during auto-save', e);
    }
  }
  
  useEffect(() => {
    projectIdRef.current = project.id; // Update the current project ID reference on project change
    const debouncedSaver = debounce(() => {
      if (project?.id === projectIdRef.current) {
        silentSaver();
      }
    }, DEBOUNCE_INTERVAL);
    debouncedSaver();
    return () => {
      debouncedSaver.cancel(); // Cancel pending debounce
    };
  }, [project.id]); // Debounce whenever the project ID changes

  return (
    <Sticky>
      {({ style }) => (
        <div className="flex-row background-white justify-space-between" style={{ ...style, zIndex: 10 }}>
          <Default {...props} style={{ width: '100%' }} />
          <div className="flex-row" style={{ display: 'none' }}>
            <Button style={{ margin: `1rem 1rem 1rem 0` }} type="default" onClick={() => saver()}>
              Save
            </Button>
            <Button
              style={{ margin: `1rem 0 1rem 0` }}
              type="primary"
              onClick={() => saver(`/projects/${project.id}/manage`)}
            >
              Save and Exit
            </Button>
          </div>
        </div>
      )}
    </Sticky>
  );
}

export default connect()(TabBar);
